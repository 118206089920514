import criteria from "src/utils/criteria";
import { typeOfSaleList, livingArrangementsTypes } from "src/constants";

const {
  PRIVATE_SALE,
  DEALER_SALE,
  SALE_BUYBACK_EQUITY,
  MID_TERM_REFINANCE,
  BALLOON_RESIDUAL_REFINANCE,
} = typeOfSaleList;

const {
  PROPERTY_OWNER, LIVING_WITH_PARENTS, RENTING, BOARDING
} = livingArrangementsTypes

const {
  livingArrangements,
  nonGST,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetGreaterThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  typeOfSale,
  brokerageIsBetween,
  brokerageIsEqualTo,
} = criteria;

export default {
  date: "01 Dec 2023",
  lender: "AMMF",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/ammf-portrait-normal-rgb.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Authorised supplier",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(DEALER_SALE)],
    },
    {
      name: "Private sale",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(PRIVATE_SALE)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 1000000)],
    },
  ],
  loading: [
  ],
  info: [
    `
      Low Doc – Commercial Applications (only applicable for lends / total exposure under $50K)
    `,
    `
     To assess with “No Proof of Income “ - Max term 60 months, No Balloon
    `,
    `
     Finance commission is calculated at 70% overs.
    `,
  ],
  productTiers: [
    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(21),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(21),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        livingArrangements(["Property owner"]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        livingArrangements(["Property owner"]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Full doc",
      rate: 8.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Full doc",
      rate: 8.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Full doc",
      rate: 8.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },




    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(13),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(13),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(21),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(21),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(6),
        livingArrangements(["Property owner"]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(6),
        livingArrangements(["Property owner"]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 34999.99),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(6),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Low doc",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(6),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },


    {
      name: "Full doc",
      rate: 9.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Trailers and Caravans"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Full doc",
      rate: 9.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Trailered boats"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Full doc",
      rate: 9.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(50000.01, 1000000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(6),
        assetType([
          "Jetskis",
          "Ride on mowers",
          "Golf karts"
        ]),
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE,
          PRIVATE_SALE
        ]),
        hasDeposit(25),
        creditScoreGreaterThan(550),
      ],
    },
  ],
};
