import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import {
  saveValue,
  startApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
  saveCustomer,
} from "../store/slices/applicationFormSlice";
import mixpanel from "src/services/mixpanel";
// import _ from "lodash";

function StartApplicationDialog({ open, handleClose, startApplication }) {
  return <Dialog
    onClose={handleClose}
    open={open}
  >
    <DialogTitle>Select quote type</DialogTitle>
    <List sx={{ pt: 0 }}>

      <ListItem >
        <ListItemButton
          onClick={() => startApplication("consumer")} key="startConsumerAssetApp"
        >
          <ListItemText
            primary="Consumer Asset"
          />
        </ListItemButton>
      </ListItem>
      <ListItem >
        <ListItemButton
          onClick={() => startApplication("commercial")} key="startCommercialAssetApp"
        >
          <ListItemText
            primary="Commercial Asset"
          />
        </ListItemButton>
      </ListItem>

      <ListItem >
        <ListItemButton
          disabled
        // onClick={() => startApplication("personal")} key="startCommercialAssetApp"
        >
          <ListItemText
            primary="Personal Loan (coming soon)"
          />
        </ListItemButton>
      </ListItem>
      <ListItem >
        <ListItemButton
          disabled
        // onClick={() => handleListItemClick(email)} key={email}
        >
          <ListItemText
            primary="Unsecured Business Loan (coming soon)"
          />
        </ListItemButton>
      </ListItem>
    </List>
  </Dialog>
}

export default function StartApplication({ buttonText }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isNewApplication } = useSelector(userDetailsSelector);
  const { _id, applicationType } = useSelector(applicationSelector);
  const [openDialog, setOpenDialog] = useState(false)
  // const [applicationType, setApplicationType] = useState(null)

  function handleClose() {
    setOpenDialog(false)
  }

  function handleStartApplication(applicationType) {
    // setApplicationType(applicationType)
    dispatch(startApplication(applicationType))
  }

  useEffect(() => {
    // console.log("isNewApplication", isNewApplication);
    // console.log("_id", _id);
    if (isNewApplication && _id) {
      mixpanel.track(`New quote`, {
        applicationId: _id,
        applicationType
      })
      dispatch(saveValue({ isNewApplication: false }));

      navigate(`/quote/${_id}`);
      // window.location.reload()
      handleClose()
    }
  }, [isNewApplication]);

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={async () => {
          // console.log("start application");
          // dispatch(startApplication());
          setOpenDialog(true)
        }}
        style={{ marginRight: "30px" }}
      >
        {buttonText || "New quote"}
      </Button>
      <StartApplicationDialog
        open={openDialog}
        handleClose={handleClose}
        startApplication={handleStartApplication}
      />
    </>
  );
}
