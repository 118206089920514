import ammf from "./ammf";
import angle from "./angle";
import azora from "./azora";
import selfco from "./selfco";
import ggf from "./ggf";
import westpac from "./westpac";
import macquarie from "./macquarie";
import flexi from "./flexi";
import grow from "./grow/index.js";
import resimac from "./resimac";
import liberty from "./liberty";
import grenke from "./grenke";
import pepper from "./pepper";
import metro from "./metro";
import scotpac from "./scotpac";
import anz from "./anz";
import plenti from "./plenti";
import financeOne from "./financeOne";
import shift from "./shift";
import shiftEquipment from "./shift/equipment"
import capital from "./capital";
import vwfs from "./vwfs";

export default [
    ammf,
    angle,
    anz,
    azora,
    capital,
    financeOne,
    flexi,
    ggf,
    grenke,
    grow,
    liberty,
    macquarie,
    metro,
    pepper,
    plenti,
    resimac,
    scotpac,
    selfco,
    shift,
    shiftEquipment,
    westpac, 
    vwfs
];
