export const typeOfSaleList = {
    DEALER_SALE: "Authorised seller",
    EV_SALE: "Authorised electric vehicle seller",
    PRIVATE_SALE: "Private sale",
    MID_TERM_REFINANCE: "Mid-term refinance",
    BALLOON_RESIDUAL_REFINANCE: "Balloon / Residual Refinance",
    SALE_BUYBACK_EQUITY: "Sale & buyback / Equity"
}

export const assetTypes = {
    MOTOR_VEHICLE_UNDER_4_5_TONNES: "Motor Vehicle (<4.5 tonnes)",
    COMMERCIAL_SMALL_MEDIUM_LESS_THAN_12_TONNES: "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
    COMMERCIAL_HEAVY_VEHICLE_GREATER_THAN_12_TONNES: "Commercial (Heavy) Vehicles (>12 Tonnes)",
}

export const productTypes = [
    "Standard",
    "Low doc",
    "Light doc",
    "Full doc",
    "Adverse"
];

export const livingArrangementsTypes = {
    PROPERTY_OWNER: "Property owner",
    RENTING: "Renting",
    BOARDING: "Boarding",
    LIVING_WITH_PARENTS: "Living w/ parents"
}

export const criteriaTypes = [
    "No deposit",
    "No property",
];

export const commercialLenderList = [
  "AMMF",
  "Angle Finance",
  "ANZ",
  "Azora Asset Finance",
  "Captial Finance",
  "Dynamoney",
  "Finance One",
  "Flexi",
  "Group & General Finance",
  "GRENKE",
  "Liberty",
  "Macquarie",
  "Metro",
  "Pepper",
  "Plenti",
  "resimac",
  "ScotPac",
  "Selfco",
  "Shift",
  "Westpac",
  "Volkswagen Financial Services",
];

export const consumerLenderList = [
  "Affordable Car Loans",
  "Australian Premier Finance",
  "Car Start",
  "Finance One",
  "Gedda Money",
  "Greenlight",
  "Liberty",
  "Macquarie",
  "Money3",
  "MoneyPlace",
  "NOW",
  "Pepper",
  "Plenti",
  "WISR",
];

export const sortList = [
    ["fit", "desc"],
    ["repayments", "asc"],
    ["rate", "asc"],
    ["loanAmount", "asc"],
    ["fees", "asc"],
];

export const employmentTypes = {
    FULL_TIME: "Full-time",
    PART_TIME: "Part-time",
    CASUAL: "Casual",
    CONTRACT: "Contract",
    SELF_EMPLOYED: "Self employed",
    BENEFITS: "Benefits"
}
