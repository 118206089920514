import criteria from "src/utils/criteria";
import motorMisc from "./motorMisc"
import motorBike from "./motorbike"
import ATVMowersTractors from "./ATVMowersTractors"
import JetskiPWC from "./JetskiPWC"
import trailers from "./trailers"
import horseFloat from "./horseFloat"

const { loanAmountBetween, assetValueBetween } = criteria;

export default {
  date: "01 Jul 2023",
  lender: "NOW",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FNOWFinance-Logo.svg",
  actions: ["upload"],
  lenderCredentials: [
    // {
    //   label: "API Token",
    //   attribute: "apiKey",
    // },
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make",
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "middleNames",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      // "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 350,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 350,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(3000, 4999.99)],
    },
    {
      name: "Origination fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 7999.99)],
    },
    {
      name: "Origination fee",
      value: 880,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(8000, 9999.99)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 19999.99)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 49999.99)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(50000, 150001)]
    },
  ],
  brokerage: [
  ],
  loading: [],
  info: [
    "No lender fees",
    `New =<12 months build date / Demo = <24 months build date <5000kms.`,
    `Asset backed customers with credit score <600 are subject to tier 3 LVR and max lend`,
  ],
  productTiers: [
    ...motorMisc,
    ...motorBike,
    ...ATVMowersTractors,
    ...JetskiPWC,
    ...trailers,
    ...horseFloat
  ]
};