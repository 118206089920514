import criteria from "src/utils/criteria";

const {
  livingArrangements,
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  compound
} = criteria;

export default {
  date: "31 Oct 2023",
  lender: "Westpac",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FWestpac.png",
  fees: [
    {
      name: "Private sale fee",
      value: 595,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")]
    },
    {
      name: "Document fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 250000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    // },
    {
      name: "Private sale",
      value: 0.42,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale"), assetType(["Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Term >48",
      value: 0.20,
      valueType: "percentage",
      criteria: [termIsGreaterThan(48)],
    }
  ],
  info: [
    `For existing Westpac customers, Primary Assets borrowing limits extend to $250K with a total aggregate $500K`,
    `New Westpac customer total aggregate $250K`,
    `Medical Industry Doctors must have +3 years industry experience`,
    `Motor Vehicle up to 5 yrs old <$250,000`,
    `New Office equipment & fittings <$150,000`,
    `New Medical equipment <$350,000`
  ],
  productTiers: [
    {
      name: "Xpress Auto",
      rate: 7.97,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetLessThan(60),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Xpress Primary asset",
      rate: 8.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetLessThan(84),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Xpress Primary asset",
      rate: 8.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetLessThan(60),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Xpress (MEDICAL PROFESSIONALS ONLY)",
      rate: 8.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 350000),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        ageOfAssetLessThan(60),
        assetType([
          "Medical Equipment",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Xpress (MEDICAL PROFESSIONALS ONLY)",
      rate: 8.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        ageOfAssetLessThan(60),
        assetType([
          "Office Equipment",
          "IT, Computer & Audio Equipment"
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(400)
      ],
    },
  ],
};